.HelpTooltip {
  width: unset !important;
}

.help, .info {
  margin-left  : 3px;
  margin-bottom: 3px;
  line-height  : unset;
  color        : var(--link-color);
  display: flex;
  justify-content: center;
}

.flex-help {
  display: flex !important;
}