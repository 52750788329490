
.context-selector-component-title {
  font-size: 16px;
  font-weight: 400;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: flex
}

.context-selector-orgs-info::before{
  content: '('
}
.context-selector-orgs-info::after{
  content: ')'
}
.context-selector-orgs-info {
  margin-left: 2px;
  font-size: 14px;
  font-weight: 500;
  overflow-x: hidden;
  display: flex;
}
.context-selector-cursor {
  cursor: pointer;
}

.context-selector-orgs-info-color {
  color: var(--link-color) !important;
  text-decoration: underline;
}

.context-selector-component-block {
margin-top: 10px;
cursor: pointer;
}

.context-selector-info {
display: flex;
align-items: center;
justify-content: space-between;
font-weight: 400;
font-size: 22px;
}

.context-selector-modal-content {
  height: fit-content;
  display: grid;
  /*grid-template-columns: 3fr 5fr;*/
  grid-gap: 39px;
}

.context-selector-opts-block{
  width: 100%;
}
.context-selector-header {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  font-weight: 600;
  font-size: 22px;
}

.context-selector-orgs-info-root {
  display: flex;
  justify-content: center;
}
