.videoPlayerTrigger {
  min-width: 150px;
  min-height: 100px;
  background: rgb(46, 46, 46);
  color: var(--acuwhite);
  border-radius: 10px;
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 5px;
  font-size: 8pt;
}

.videoPlayerTrigger .playWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-width: 5px;
  border-color: var(--acuwhite);
  border-style: solid;
}