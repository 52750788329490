.inputfile {
  display: none;
}

.inputfile + label {
  border: solid;
  border-width: 2px;
  border-radius: 5px;
  font-size: smaller;
  padding: 5px 10px;
  width: 25%;
  margin: 0% auto;
  cursor: pointer;
}

.disabled .inputfile {
  color:lightgray;
  border-color: lightgray;
  cursor: not-allowed;
}

.disabled label {
  cursor: not-allowed;
}