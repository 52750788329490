@import '../../style.css';


.avgRateReport .icon {
  height: max-content !important;
  width: max-content !important;
  margin-right: 30px !important;
}

.snoreReport .icon {
  margin-right: 30px !important;
}

.rateReport .rateHistogram, .gauge {
  background-color: var(--acuwhite);
}

.help-text {
  font-weight: normal;
  text-align: start;
  text-transform: none;
}

.rateReport .rateAVGText {
  margin-right: 10px;
  text-transform: uppercase;
}

.rateReport .rateAVGValue {
  font-weight: 800;
}

/* Selector based on useWindowSize.js -> isSmallScreen */
@media (max-width: 800px) {
  .rateReport .rateHistogram {
    height: 250;
  }

  .avgRateReport .icon {
    margin-right: 15px !important;
  }
}

#PositionPerHour {
  background-color: var(--acuwhite);
  color: var(--details-text-color);
}

#PositionPerHour .hline {
  border-bottom: 2px solid #888;
}

#PositionPerHour .position-row {
  margin-top: 0;
}

#PositionPerHour .position-type, #PositionChart .position-hour {
  text-wrap: nowrap;
  max-width: 10%;
  min-width: 10%;
}

#PositionPerHour .hour-value {
  text-align: center;
  margin-left: 0;
}

#PositionPerHour .hour-row .hour-value {
  font-size: x-small;
  background-color: lightgray;
  color: white;
}

#PositionPerHour .hour-value:not(:last-child) {
  border-right: 0.5px solid #dedede;
}

#PositionPerHour .position-row > * {
  line-height: 50px;
}