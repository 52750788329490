#CreateStudy .stepProgress {
  width: 100%;
  line-height: 14pt;
  border-radius: 5px;
  padding: 1.2em;
  background: var(--light-acugrey);
  display: inline-flex;
  align-items: center;
}

#CreateStudy .stepProgress .label {
  margin: 0;
}

#CreateStudy .stepProgress > *:not(:first-child) {
  margin-left: 10px;
}

#CreateStudy .stepProgress > .step:not(:last-child)::after {
  content: "\0203A";
  font-size: 14pt;
  font-weight: bold;
  color: var(--dark-acugrey);
  margin-left: 10px;
}

#CreateStudy .stepLabel {
  color: var(--acugrey);
  font-size: calc(var(--fontSize) - 2pt);
}

#CreateStudy .step.active .stepLabel {
  color: initial;
}

#CreateStudy .stepNum {
  border-radius: 50%;
  display: inline-block;
  font-size: calc(var(--fontSize) - 2pt);
  font-weight: normal;
  padding: 5px;
  text-align: center;
  min-width: 30px;
  max-width: max-content;
  min-height: 30px;
  max-height: max-content;
  background: var(--acugrey);
  color: var(--acuwhite);
  margin-right: 8px;
}

#CreateStudy .step .stepNum .total {
  display: none;
}

#CreateStudy .step.active .stepNum {
  background: var(--acuyellow);
}

@media (max-width: 1000px) {
  #CreateStudy .step:not(.active) .stepLabel {
    display: none;
  }
}
@media (max-width: 900px) {
  #CreateStudy .step:not(.active) {
    display: none;
  }
  #CreateStudy .stepProgress > .step:not(:last-child)::after {
    display: none;
  }
  #CreateStudy .step .stepNum {
    display: inline-flex;
  }
  #CreateStudy .step .stepNum .total {
    display: block;
  }
}

#CreateStudy textarea.field, #EditStudy textarea.field {
  height: initial;
}

#CreateStudy .codeImage {
  align-self  : center;
  justify-self: center;
}

#CreateStudy .codeImage.printImg {
  max-width   : 200px;
}

#StudyInstructions .instructionImg img, #CreateStudy .instructionImg img {
  max-width     : 45%;
  max-height    : 150px;
}

#StudyInstructions .instructionImg, #CreateStudy .instructionImg {
  width          : 45%;
  display        : flex; 
  align-items    : center;
  justify-content: center;
}

#StudyInstructions .detailsBlockContent * {
  line-height: 1.5;
}

#StudyInstructions .activationCode {
  align-self: center;
  border-style: dashed;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: larger;
}

#CreateStudy .downloadPDF > *:not(:last-child) {
  margin-right: 5px;
}

#CreateStudy .stepsIntro {
  margin-bottom: 25px;
}

#patientIDField input {
  text-transform: uppercase;
}

#CreateStudy .italic {
  font-style: italic;
}

.errorIcon {
  fill: var(--acuwhite);
  color: var(--acured);
}

.videoPlayerModal .modal-body {
  display: flex;
  justify-content: center;
}

#CreateStudy .profileBlock {
  background-color: var(--light-acugrey);
  border-color: var(--transparent-medium-acugrey);
  border-width: 2px;
  padding: 3px 10px;
}

#CreateStudy .sectionHeader .title {
  /* same as .page .title TODO: refactor */
  color: var(--header-text-color);
  font-size: var(--titleSize);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

#CreateStudy .title-page {
  font-weight: 700;
}

#CreateStudy .title-section {
  font-weight: 600;
}