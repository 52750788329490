
@import '../style.css';

.quickfilters .item {
  background-repeat    : no-repeat;
  background-attachment: scroll;
  background-position  : 5px 50%;
  background-size      : 20px;
  padding              : 5px;
  cursor               : pointer;
  text-align           : end;
  text-transform       : uppercase;
  font-size            : 10pt;
  font-weight          : 600;
  border-radius        : 4px;
  margin               : 1px 1px 1px 10px;
  color                : var(--dark-acugrey);
}

.item.selected {
  outline: 2px solid #2f2f2f;
  outline-offset: -2px;
}

@media (max-width: 720px) {
  .collapsed {
    display: block;
  }

  .expanded {
    display: none;
  }

  .canBeHidden {
    display: none;
  }
}

@media (min-width: 721px) {
  .collapsed {
    display: none;
  }

  .expanded {
    display: inline-block;
  }
}

@media (max-width: 575.98px) { 
  .input {
    line-height: 23px;
  }
}

#FindStudy .studyWarnIcon {
  width: max-content;
}

#FindStudy #periodFilterHelp {
  max-width: 500px;
}

@media (max-width: 1100px) { 
  #FindStudy .item {
    font-size:  calc(var(--fontSize) - 4pt);
  }
}

@media (max-width: 770px) { 
  #FindStudy .item {
    font-size:  calc(var(--fontSize) - 5.2pt);
  }
}

#FindStudy .item {
  box-shadow: 1px 1px 2px #666666;
}

#ShowArchived {
  margin-left: auto;
}

#FindStudy .actions-layout {
  display: flex;
  align-items: center;
}

#FindStudy .help {
  margin: 0;
  padding: 0;
}

#FindStudy .archived-icon *> svg {
  color: var(--acublack);
}
#FindStudy .archived-icon {
  justify-content: center;
  display: flex;
}

#FindStudy .filters-block {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.find-study-advance-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--link-color) !important;
  font-size: 14px;
  font-weight: 500;
}
.find-study-advance-search > .title {
  margin: 0;
}

.find-study-margin {
  margin-top: 14px !important;
}

#ResultsWarning.warning {
  align-items: center;
  background-color: var(--warning-background-color);
  border-color: var(--warning-border-color);
}

#ResultsWarning.warning .content > *:first-child {
  width: 50px;
  height: 50px;
}

tr.error {
  background-color: lightcoral;
}

tr.success {
  background-color: lightgreen;
}

tr.highlight {
  background-color: rgba(0,0,0,0.075);
  outline: thin solid #D3D3D3;
}