.schema-form-group {
    width: 100%;
}

.schema-form-group-blocks {
   display: flex;
   justify-content: center;
   flex-direction: column;
}

.schema-form-group-childs {
    padding-top: 20px;
}