.schema-form-title {
    width: 100%;
    font-size: 16px;
    margin: 0;
    display: flex;
    align-items: center;
}

.schema-form-title .required {
    color: var(--acured);
}