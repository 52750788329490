.modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.modal-footer {
  border-top: none;
}

.modal-centered {
  margin: 0 auto;
}

.modal-fullscreen {
  transform: none;
  width    : 90%;
  max-width: unset;
}

.modal-full-w {
  width: 100%;
}