#StudyInstructions .printImg img {
  max-width     : 20%;
  max-height    : 150px;
}

#StudyInstructions .printImg {
  width          : 20%;
  display        : flex; 
  align-items    : center;
  justify-content: center;
  margin         : 20px;
}

#StudyInstructions .detailsBlockContent * {
  line-height: 1.5;
}

#CreateStudyBatch .td.withError {
  color: red;
  font-weight: 600;
}

#CreateStudyBatch .errorTooltip {
  width: auto;
  width: max-content;
}

#CreateStudyBatch .errorTooltip,
#CreateStudyBatch .errorTooltip::before,
#CreateStudyBatch .errorTooltip::after {
  color: red;
  font-weight: 600;
}

#CreateStudyBatch .th {
  color: var(--acugrey);
  font-weight: 600;
  margin-bottom: 10px;
}

#CreateStudyBatch .resultIndicator {
  width: 24px;
}

#CreateStudyBatch .resultIndicator.success {
  color: green;
  fill: green;
}
#CreateStudyBatch .resultIndicator.error {
  color: red;
  fill: red;
}

#DownloadStudyDetailsModal.modal-dialog {
  max-width: 70%;
}

.row-expansion-style, .row-expansion-style .title, .row-expansion-style .title span, .row-expansion-style .question {
  font-size: smaller !important;
}

.ignored {
  background-color: lightgray;
}

tr.withError {
  background-color: lightcoral;
}


td.withError {
  background-color: lightcoral;
}

.fulfilled {
  background-color: lightgreen;
}

.pending {
  background-color: lightgray;
}

#Errors .details .value {
  line-height: unset;
}

#Errors th, #Errors td {
  font-size: small;
}

#DefaultSettings .title {
  text-transform: uppercase;
  color: #999999;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: var(--details-name-size);
}

#Batch #Data {
  overflow-x: auto;
}

.row-expansion-style {
  width: fit-content;
}