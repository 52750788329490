@import '../../style.css';


.avgRateReport .icon {
  height: max-content !important;
  width: max-content !important;
  margin-right: 30px !important;
}

.snoreReport .icon {
  margin-right: 30px !important;
}

.rateReport .rateHistogram, .rateWhisker {
  background-color: var(--acuwhite);
}

.rateReport .rateAVGText {
  margin-right: 10px;
  text-transform: uppercase;
}

.rateReport .rateAVGValue {
  font-weight: 800;
}

.chartTitle, .chartSubtitle {
  align-self: center !important;
  display: flex !important;
  justify-content: center !important;
  color: rgb(101, 101, 101) !important;
  font-size: 14pt !important;
  font-weight: 700 !important;
  text-rendering: optimizeLegibility !important;
  letter-spacing: 0.001ex !important;
}

.chartSubtitle > span + span {
  margin-left: 40px;
}

.chartSubtitle {
  /* color: black !important; */
  border-radius: 10px;
  background: #9bb9d9 !important;
  font-size: 12pt !important;
  font-style: italic !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

@media screen and (max-width: 800px) {
  .chartSubtitle {
    font-size: 6;
    padding: 10;
  }

  .chartTitle {
    font-size: 7pt !important;
  }
}

/* Selector based on useWindowSize.js -> isSmallScreen */
@media (max-width: 800px) {
  .rateReport .rateHistogram {
    height: 250;
  }

  .avgRateReport .icon {
    margin-right: 15px !important;
  }
}

.detailsBlock .Params {
  color: black;
  background-color: white;
}


.detailsBlock .Params table th, .detailsBlock .Params table td {
  color: black;
  text-align: center;
}

.detailsBlock .Params table th:first-child, .detailsBlock .Params table td:first-child {
  text-align: left;
}

.detailsBlock .Params table tr:first-child {
  border-bottom: 1pt solid black;
}

#CE_WARNING {
  background-color: white;
  border: 2px solid #B3B3B3;
}

#CE_WARNING .value {
  color: rgb(47, 47, 47);
  font-weight: unset;
}

#POSITION_WARNING {
  background-color: white;
  border: 2px solid #B3B3B3;
}

#POSITION_WARNING .value {
  color: rgb(47, 47, 47);
  font-weight: unset;
}

.details .icon {
  width: 60px;
  height: 60px;
}

.reportCard table, .reportCard table th, .reportCard table td, .reportCard table td span {
  font-size: 12pt;
}

.spo2-warning .icon {
  width: 40px;
}

.spo2-warning .text {
  font-size: 14px;
}

.reportCard .detailsBlock {
  margin: 0;
}

.reportCard .mb:not(:last-child) {
  margin-bottom: 24px;
}

.SignalChart {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.SignalChart > .button {
  margin-right: 30px;
}