@import '../style.css';

#ViewStudy .detailsBlock.warning, #ViewStudy .detailsBlock.error {
  align-items: center;
  background-color: var(--warning-background-color);
  border-color: var(--warning-border-color);
}

.warningIcon, .warningIcon svg {
  color : var(--warning-border-color);
  fill  : var(--acuwhite);
}

.exclamationIcon {
  --circle-color: var(--warning-border-color);
  --exclamation-color: var(--acuwhite);
}

.cancelledIcon, .cancelledIcon svg {
  color : var(--warning-border-color);
  fill  : var(--acuwhite);
}

#testReportMessages .warning {
  background-color: unset;
}

#ViewStudy .warning .content > *:first-child {
  width : 50px;
  height: 50px;
}

#ViewStudy .error .content > *:first-child {
  width : 50px;
  height: 50px;
}

#ViewStudy .error .message {
  font-style: italic;
  font-weight: bold;
}

#ViewStudy .content .title {
  text-transform: none;
  margin-top    : 10px;
}

#ViewStudy #healthcaresiteName .button,
#ViewStudy #clinicianName .button,
#ViewStudy #trialName .button {
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: auto;
  max-width: 100%;
}

#ViewStudy .status.numTestsValue {
  width: max-content;
  min-width: unset;
}

#ViewStudy .testBlock:not(.ANALYSED) .details .name, #ViewStudy .testBlock:not(.INVALID) .details .name {
  font-weight: 500;
  color: #000000;
}

#ViewStudy .testBlock .details .value {
  font-weight: 300;
}

#ViewStudy .testBlock *:not(.status) {
  font-size: 12pt;
}

#ViewStudy .testBlock.ANALYSED, #ViewStudy .testBlock.INVALID {
  background-color: #004366;
}

#ViewStudy .testBlock.ANALYSED .details .detailsHeader .name,
#ViewStudy .testBlock.INVALID .details .detailsHeader .name,
#ViewStudy .testBlock.ANALYSED .details .value,
#ViewStudy .testBlock.ANALYSED .details .value *:not(.status),
#ViewStudy .testBlock.INVALID .details .value,
#ViewStudy .testBlock.INVALID .details .value *:not(.status) {
  color: #FFFFFF;
}

#ViewStudy .testBlock.PENDING {
  border-style: dashed;
  border-width: 3px;
  border-color: #C4C5C5;
  background-color: #EDEEEF;
  color: #2F2F2F;
}

#ViewStudy .testBlock.STARTED, #ViewStudy .testBlock.CONDUCTED, #ViewStudy .testBlock.CANCELLED {
  border-style: dashed;
  border-width: 3px;
  border-color: #EFBE25;
  background-color: #FFF1CB;
  color: #2F2F2F;
}

#ViewStudy .testBlock.CANCELLED {
  border-style: solid;
}

#ViewStudy .testBlock #testStatusField .value .status.STARTED,
#ViewStudy .testBlock #testStatusField .value .status.PENDING{
  background-color: #ffffff;
  color: #666666;
}

#ViewStudy .testSummary {
  padding: 5px 20px;
  width: 60vw;
  max-width: 500px;
  text-transform: uppercase;
  font-family: sans-serif;
  border-radius: 15px;
  margin: 0 20px;
}

#ViewStudy .testSummary > * {
  height: 100%;
}

#ViewStudy #testNightField {
  text-align: center;
}

#ViewStudy #testNightField, #ViewStudy #testViewField {
  width: 10vw;
  max-width: 150px;
  min-width: 60px;
}

#ViewStudy #testViewField {
  width: 15vw;
}

#ViewStudy #testStatusField {
  width: 10vw;
  max-width: 250px;
  min-width: fit-content;
}

#ViewStudy #testConductedField {
  width: 5vw;
  max-width: 250px;
  min-width: 115px;
}

#ViewStudy #testViewField .secondary {
  font-weight: 400;
  padding: 5px 8px;
}

@media (max-width: 900px) { 
  #ViewStudy .patientDetailsImage { display: none; } 
}

#ViewStudy .profileBlock {
  background-color: var(--light-acugrey);
  border-color: var(--transparent-medium-acugrey);
  border-width: 2px;
  padding: 3px 10px;
}

#ViewStudy .feedbackBlock {
  background-color: var(--light-acugrey);
  border-color: var(--transparent-medium-acugrey);
  border-width: 2px;
  padding: 3px 10px 10px 10px;
}

#ViewStudy .feedbackBlock .warningIcon {
  width: 60px;
}

#ViewStudy .feedbackBlock .exclamationIcon {
  width: 60px;
}

#ViewStudy .warnText {
  margin-left: 5px;
}

@media (max-width: 410px) {
  .canBeHiddenAt410 {
    display: none;
  }
}

@media (max-width: 470px) {
  .page .actions {
    flex-wrap: wrap;
    flex-shrink: 2;
  }

  .page .actions > * {
    margin: 3px;
  }

  .pageHeader .title {
    margin-right: 0px;
    padding-right: 10px;
  }
}

#ViewStudy .italic {
  font-style: italic;
}

.preserve-line-breaks {
  white-space: pre-line;
  overflow-wrap: anywhere;
}


.clinician-analysis-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.clinician-analysis-import {
  margin-left: 16px;
}