@import './button.css';
@import './colors.css';
@import './form.css';
@import './grid.css';
@import './page.css';
@import './progress.css';

/* TODO: deprecated - remove once Acukit fully cleaned from inside web-app */
@import './breakPoints.css';

@media (max-width: 350px) { :root { --fontSize: 8pt; } }
@media (min-width: 350.01px) and (max-width: 575.98px) { :root { --fontSize: 10pt; } }
@media (min-width: 576px) { :root { --fontSize: 12pt; } }

@media (max-width: 350px) { :root { --titleSize: 10pt; } }
@media (min-width: 350.01px) and (max-width: 575.98px) { :root { --titleSize: 12pt; } }
@media (min-width: 576px) { :root { --titleSize: 17pt; } }

body {
  margin: 0;
  padding: 0;
  color: var(--font-color);
  font-family: sans-serif;
  font-size: var(--fontSize) !important;
  font-weight: 250;
}

th {
  text-transform: uppercase;
  font-weight: bold;
}

a {
  color: var(--link-color);
}

.pdfContent {
  width: 100%;
  height: 95%;
  padding-top: 20px;
}

.centered {
  text-align: center;
  justify-content: center;
  display: flex;
}

.vcentered {
  justify-items: center;
  display: flex;
}

.vcentered.centered {
  justify-items: unset;
  align-items: center;
}

span.vcentered {
  vertical-align: middle;
}

.hidden {
  visibility: hidden;
}

.clickable {
  cursor: pointer;
}

.HelpTooltip {
  background-color: var(--light-acuyellow);
  border-color: var(--acuyellow) !important;
  border-width: 3px !important;
  font-size: 10pt;
  opacity: 100 !important;
}

@media (max-width: 575.98px) {
  .HelpTooltip {
    font-size: 8pt;
  }
}

.HelpTooltip.__react_component_tooltip.type-light.border.place-right:before {
  border-right-color: var(--acuyellow) !important;
}

.HelpTooltip.__react_component_tooltip.type-light.border.place-top:before {
  border-top-color: var(--acuyellow) !important;
}

.HelpTooltip.__react_component_tooltip.type-light.border.place-right:after {
  border-right-width: 8px !important;
}

.HelpTooltip.__react_component_tooltip.type-light.border.place-left:before {
  border-left-color: var(--acuyellow) !important;
}

.HelpTooltip.__react_component_tooltip.type-light.border.place-bottom:before {
  border-bottom-color: var(--acuyellow) !important;
}

.maxTextWidth60 {
  max-width: 60ex;
}

.centeredBox {
  min-height     : 100vh;
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.upperCase {
  text-transform: uppercase;
}

.fullWidth {
  width: 100%;
}

.bold {
  font-weight: bold;
}

.small-text {
  font-size: x-small;
}

ul {
  padding-left: 30px;
}

.warningIcon, .warningIcon svg {
  color : var(--warning-border-color);
  fill  : var(--acuwhite);
}

.infoIcon, .infoIcon svg {
  color : var(--info-icon-color);
  fill  : var(--acuwhite);
}

.errorIcon, .errorIcon svg {
  color : var(--error-border-color);
  fill  : var(--acuwhite);
}

.help svg {
  color : var(--link-color);
}

/* coockieconsent styles */

.cc-window {
  border: solid 3px var(--sw-border-color) !important;
  background-color: var(--sw-background-color) !important;
  color: var(--font-color) !important;
}

.cc-window .cc-btn:hover {
  background-color: var(--btn-highlight-color) !important;
}

.cc-window .cc-btn {
  background-color: var(--action-second-background-color) !important;
  color: var(--action-second-text-color) !important;
  text-decoration: none !important;
  outline: none !important;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}