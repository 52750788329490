/* Use of grid css rules is not considered due to compatiblity with IE 11 */

 /* grid-col class Used for columns wih dynamic number of elements */
 /* TODO: we should use scss/sass to inherit other styles instead of duplicating code */
.grid-col, .small-grid-col { 
  display: flex;
  flex-direction: row;
}
.grid-col:not(.fitContent) > *:not(.fitContent), .small-grid-col:not(.fitContent) > *:not(.fitContent) {
  width: 100%;
}

.grid-col.vcentered {
  align-items: center;
}

.grid-col.centered {
  justify-content: center;
}

.inline > *:not(:first-child), .grid-col > *:not(:first-child), .small-grid-col > *:not(:first-child) {
  margin-left: 10px;  
}

.small-grid-col > *.vcentered, .grid-col > *.vcentered {
  align-self: center; 
}

.grid-col > *.alignRight {
  margin-left: auto;
}

/* grid-row class used for rows wih dynamic number of elements */
/* TODO: we should use scss/sass to inherit other styles instead of duplicating code */
.small-grid-row, .grid-row { 
  display: flex;
  flex-direction: column;
}

.grid-row.centered {
  align-items: center;
}

.small-grid-row.vcentered, .grid-row.vcentered { 
  justify-content: center;
}

.small-grid-row > *.vcentered, .grid-row > *.vcentered {
  justify-self: center; 
}

.small-grid-row > *.centered, .grid-row > *.centered {
  align-self: center; 
}

.small-grid-row > *:not(:first-child), .grid-row > *:not(:first-child) {
  margin-top: 10px; 
}

/* TODO: we should use scss/sass to inherit other styles instead of duplicating code */
/*@media (min-width: 576px) { 
  .grid-col, .large-grid-col { 
    display: flex;
    flex-direction: row;
  }
  .grid-col > *, .large-grid-col > * {
    width: 100%; 
  }
  
  .grid-col > *:not(:first-child), .large-grid-col > *:not(:first-child) {
    margin-left: 10px;
  }

  .large-grid-col > *.vcentered, .grid-col > *.vcentered {
    align-self: center; 
  }

  .large-grid-row, .grid-row { 
    display: flex;
    flex-direction: column;
  }

  .large-grid-row.vcentered, .grid-row.vcentered { 
    justify-content: center;
  }

  .large-grid-row > *.vcentered, .grid-row > *.vcentered {
    align-self: center; 
  }

  .large-grid-row > *:not(:first-child), .grid-row > *:not(:first-child) {
    margin-top: 10px; 
  }
}*/