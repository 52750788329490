.PersonIcon a:focus{
    outline: none;
    outline-offset: 0px;
}

.region-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.region-container span {
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
}