#ChangeStatus .field {
  color        : rgb(112, 112, 112);
  font-size    : 12px;
  height       : 34px;
  line-height  : 32px;
  padding      : 2px;
  text-align   : left;
  border-color : rgb(226, 226, 226);
  border-style : solid;
  border-radius: 5px;
  border-width : 2px;
  width        : 164px;
}