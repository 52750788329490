.schema-form-submit-button-template-root {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.schema-form-actions-button--margin {
   margin-right: 6px;
}

