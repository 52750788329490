@media (max-width: 470px) {
  .page .actions {
    flex-wrap: wrap;
    flex-shrink: 2;
  }

  .page .actions > * {
    margin: 3px;
  }
}


#study-actions .margins {
    margin-left: 6px;
}

#study-actions  .clinician-analysis-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#study-actions .clinician-analysis-import {
  margin-left: 16px;
}

.clinician-analysis-header .flex {
  display: flex;
}