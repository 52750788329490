.loader-bar-container {
  width: 600px;
  text-align: center;
  position: relative;
}

.loader-bar-text {
  font-size: 20px;
  color: white;
  width: 100%;
}

.loader-bar-progress {
  border-radius: 30px;
  background-color: #fff;
}

.loader-progress-bar {
  height: 18px;
  border-radius: 30px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.loader-bar-info{
  width: 100%;
  display: flex;
  align-items: center;
}

.loader-bar-progress-moved .loader-progress-bar {
  background-color: #f3c623;
  animation: progress 35s;
}

@keyframes progress {
  0% {
    width: 0%;
    background: #f9bcca;
  }

  100% {
    width: 100%;
    background: #f3c623;
    box-shadow: 0 0 40px #f3c623;
  }
}

.loader-bar-icon {
  color: #f3c623;
  animation: img 35s ;
}

@keyframes img {
  0% {
    opacity: 0.2;
    text-shadow: 0 0 0 #f3c623;
  }

  100% {
    opacity: 1;
    text-shadow: 0 0 10px #f3c623;
  }
}

.loader-element {
  --p: 0;
  animation: p 36s steps(100);
  counter-reset: p var(--p);
  font-size: 2.1em;
  color: #f3c623;
}

.loader-element:after {
  content: counter(p) "%";
}

@keyframes p {
  100% {
    --p: 100;
  }
}
