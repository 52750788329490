.validatedField {
  position     : relative;
}

.validatedField.failed {
  border-width : 2px;
  border-style : solid;
  border-color : var(--acured);
  border-radius: 3px;
}

.validatedField .valdationTooltip {
  visibility      : hidden;
  border-width    : 2px;
  border-style    : solid;
  border-color    : var(--error-border-color);
  background-color: var(--error-background-color);
  color           : var(--error-text-color);
  text-align      : center;
  border-radius   : 3px;
  padding         : 2px 5px;

  /* Position the tooltip */
  position: absolute;
  top     : -32px;
  z-index : 1;
}

.validatedField.failed:hover .valdationTooltip {
  visibility: visible;
}

.validationTooltip {
  background-color: var(--error-background-color) !important;
  border-color: var(--error-border-color) !important;
  color       : var(--error-text-color) !important;
  border-width: 3px !important;
  font-size: 10pt;
  opacity: 100 !important;
}

@media (max-width: 575.98px) {
  .validationTooltip {
    font-size: 8pt;
  }
}

.validationTooltip.__react_component_tooltip {
  width: max-content !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-right:before {
  border-right-color: var(--error-border-color) !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-right:after {
  border-right-color: var(--error-background-color) !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-top:before {
  border-top-color: var(--error-border-color) !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-top:after {
  border-top-color: var(--error-background-color) !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-left:before {
  border-left-color: var(--error-border-color) !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-left:after {
  border-left-color: var(--error-background-color) !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-bottom:before {
  border-bottom-color: var(--error-border-color) !important;
}

.validationTooltip.__react_component_tooltip.type-light.border.place-bottom:after {
  border-bottom-color: var(--error-background-color) !important;
}