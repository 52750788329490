.button {
  background    : var(--btn-background-color);
  color         : var(--btn-text-color);
  padding       : 5px 10px;
  border-radius : 3px;
  min-width     : 10px;
  outline       : none;
  cursor        : pointer;
  width         : max-content;
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis; 
  line-height   : 20px;
  height        : fit-content;
  font-weight   : 500;
}

.button.noselectable {
  cursor: default;
}

a .nonselectable {
  outline: none;
  color  : var(--btn-text-color);
}

.button.small {
  font-size: 10pt;
  line-height: 10px;
}

@media (max-width: 575.98px) { .button { font-size: 9pt; } .button.small { font-size: 6pt; } }
@media (min-width: 576px) { .button { font-size: 11pt; } .button.small { font-size: 8pt; } }

.button * {
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
}

span[type='button'] {
  -webkit-appearance: none;
}

.button.add {
  min-width : 0;
}

.button.right {
  border-radius : 0 3px 3px 0;
}

.button.left {
  border-radius : 3px 0 0 3px;
}

.button.withIcon {
  align-items: center;
}

.button.fullWidth {
  max-width: 100%;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.button.high {
  line-height: 32px;
}

.button:hover:not(.disabled):not(.noselectable) {
  background: var(--btn-highlight-color);
  color: var(--acublack);
}

.button.disabled {
  cursor: default;
}

.button:not(.primary).disabled, .button:not(.secondary).disabled {
  color: white;
}

.button.primary {
  background: var(--action-background-color);
  color     : var(--action-text-color);
}

.button.admin {
  background: red;
  color     : white;
}

.button.secondary {
  background: var(--action-second-background-color);
  color: var(--action-second-text-color);
}

.button.devel {
  background: rgb(173, 61, 61) !important;
  color: var(--acuwhite) !important;
}

.button.secondary.disabled {
  background: var(--disabled-action-second-background-color);
  cursor: default;
}

.button.primary.disabled {
  background: var(--disabled-action-background-color);
}

.button.admin.disabled {
  background: lightpink;
  color: lightgrey;
}

.button.primary:hover:not(.disabled) {
  background: var(--action-highlight-color);
}

.button.admin:hover:not(.disabled) {
  background: lightcoral;
  color: white;
}

.selectable {
  cursor: pointer;
}

.noselectable {
  cursor: default !important;
}

.link {
  color: var(--link-color);
  cursor: pointer;
}

.link.disabled {
  color: unset;
  cursor: default;
}

.link:hover:not(.disabled) {
  text-decoration: underline;
}