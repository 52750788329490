span.counter {
  text-align: center;
  line-height: 30px;
}

.counter svg {
  width: 20px;
  height: 20px;
}

.counter [id*=edit] {
  padding: 4px 2px;
}

.counter [id*=edit] > * {
  vertical-align: middle;
}