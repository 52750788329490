#PageContainerViewStats {
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: rgb(235,234,206);
  background-color:  rgb(255,254,226);
}

#ViewStats .eventsChart {
  align-self: center;
}

#ViewStats .chart-title {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 18px;
  color: var(--acublack);
  text-transform: uppercase;
  font-weight: 600;
}


#ViewStats .text-title {
  font-size: 22px;
}

#ViewStats .margin {
  margin-top: 30px;
}


#ViewStats .eventTypesTable .main-group *:not(.__react_component_tooltip) {
  font-weight: 500 !important;
  text-transform: uppercase;
}

#ViewStats .eventTypesTable .main-group .__react_component_tooltip {
  font-weight: 250 !important;
  text-transform: none;
}

#ViewStats .totalFooter {
  font-weight: normal;
  text-transform: none;
}

#ViewStats .countFooter {
  font-weight: normal;
}

#ViewStats .avgFooter {
  font-weight: normal;
}

#ViewStats .eventsClassification {
  align-items: center;
}

#ViewStats .statsBlock {
  align-items: center;
}

#ViewStats .eventType {
  display: inline-flex;
  align-items: center;
  margin-left: 5px;
}

#ViewStats .footer {
  align-self: flex-start;
  font-size: smaller;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

#ViewStats .eventType .icon {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

#ViewStats .eventsPie {
  background-color: var(--acuwhite);
  width: 100%;
}

#ViewStats .pieChart {
  width: 100%;
  height: 25vh;
}

#ViewStats .eventTypesTable {
  width: 100%;
}

#ViewStats .eventTypesTable .type.group {
  font-weight: 900;
}

#ViewStats .type {
  display: flex;
}

#ViewStats .grid-col {
  justify-content: center;
}

@media (max-width: 575.98px) { 
  #ViewStats .eventsPie { padding: 5px 0; }
}
@media (min-width: 576px) {
  #ViewStats .eventsPie { padding: 10px 0; }
}