.tag.component {
    background: #eaebec;
    padding       : 5px 10px;
    border-radius : 6px;
    white-space   : nowrap;
    text-overflow : ellipsis; 
    line-height   : 20px;
    height        : fit-content;
    font-weight   : 500;
    width: 150px;
    text-align: center;
} 

.tag.component.success-color {
    background: #7bff88;
    color: #7f8c94;
} 

.tag.component.error-color {
    background: #ffdee5;
    color: #9a8489;
} 

.tag.component.warning-color {
    background: #fff1cf;
    color: #8c836e;
} 
