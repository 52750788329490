.accordion-link-button {
  display: flex;
  cursor: pointer;
  width: fit-content;
  user-select: none;
  color: var(--link-color)
}

.accordion-link-button:hover {
  text-decoration: underline;
}

.accordion-icon *>svg {
  width: 18px !important;
  cursor: pointer;
  margin-left: 4px;
}
