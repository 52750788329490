.warning .warnContent {
  color: var(--header-text-color);
}

.status {
  font-weight     : 800;
  display         : inline-block;
  width           : max-content;
  min-width       : 100px;
  text-align      : center;
  text-transform  : uppercase;
  color           : #172B4D;
  background-color: lightgray;
  border-style    : solid;
  border-radius   : 4px;
  border-width    : 3px;
  border-color    : transparent;
  line-height     : 20px;
}

.status.any {
  background-color: white;
  color: black;
  text-transform: none;
}

.status.numTestsValue {
  font-size     : 12pt;
}

@media (max-width: 460px) {
  .status {
    line-height     : initial;
    border-radius   : 4px;
    display         : inline-block;
    font-size       : 7pt;
    font-weight     : bold;
    min-width       : 70px;
    padding         : 0.2em 0.5em;
    text-align      : center;
    text-transform  : uppercase;
    color           : #172B4D;
    background-color: lightgray;
  }

  .canBeHidden {
    display: none;
  }
}

.CREATED {
  background-color: white;
  color           : #666666;
}

.PENDING {
  background-color: #cfcece;
  color           : #666666;
}

.IN_PROGRESS, .STARTED {
  background-color: #FFF1CF;
  color           : #69572b;
}

.FINISHED, .COMPLETED {
  background-color: #CCF1F1;
  color           : #546767;
}

.REVIEWED {
  background-color: #C7CFF6;
  color           : #546767;
}

.INVALID, .CANCELLED, .REPEATED {
  background-color: #ffdee5;
  color           : #6d3844;
}

.testSummary.not-analysed {
  border-style: solid;
  font-weight : 400;
  border-width: 2.95px;
  border-color: #2F2F2F;
  text-transform: none;
}

.testBlock.INVALID .testSummary.not-analysed, .testBlock.COMPLETED .testSummary.not-analysed {
  background: white;
  color: var(--acublue);
}

.testSummary:not(.not-analysed) {
  font-weight: 600;
  background: var(--light-acublue);
  color: var(--acublue);
}

.testSummary.normal {
  background: rgb(184, 211, 184);
  color: green;
}

.testSummary.normal_mild {
  background: linear-gradient(to right, rgb(184, 211, 184), rgb(247, 244, 195));
  color: green;
}

.testSummary.mild {
  background: rgb(247, 244, 195);
  color: rgb(204, 185, 18);
}

.testSummary.mild_moderate {
  background: linear-gradient(to right, rgb(247, 244, 195), rgb(247, 220, 195));;
  color: orange;
}

.testSummary.repeated {
  color: black;
  background: white;
}


.testSummary.moderate {
  background: rgb(247, 220, 195);
  color: orange;
}

.testSummary.moderate_severe {
  background: linear-gradient(to right, rgb(247, 220, 195), rgb(229, 188, 188));;
  color: rgb(187, 29, 29);
}

.testSummary.severe {
  background: rgb(229, 188, 188);
  color: rgb(187, 29, 29);
}

.report .name {
  font-family: sans-serif;
  font-weight: 600;
  font-size: 16pt;
  color: black;
}

@media (max-width: 1300px) { 
  .report .name {
    font-size: 12pt;
  }
}

.report .icon * {
  fill: varblack;
  color: black;
}

.report.summary {
  background-color: rgb(255,254,226);
}

.report.diagnosis {
  background-color: rgb(245, 228, 255);
}

.report.respiratory {
  background-color: #D2E5EF;
}

.report.signals {
  background-color: rgb(204, 241, 212);
}

.report.cardiac {
  background-color: #FFDEE5;
}

.reportCard {
  background-color: var(--acublue);
  border-color: var(--acublue);
  color: var(--acuwhite);
}

.reportCard table tbody {
  background-color: white;
}

.reportCard table th {
  font-size: 16pt;
  text-align: center;
  color: var(--acuwhite);
}

.reportCard table th:first-child {
  text-align: start !important;
}

.reportCard .akSelect, .reportCard .akSelect * {
  color: initial;
}

.reportCard .details .icon * {
  color: var(--acuwhite);
  fill: var(--acuwhite);
}

.reportCard .details .name {
  color: var(--light-acugrey);
}

.reportCard .details .value {
  color: var(--acuwhite);
  font-weight: 800;
}