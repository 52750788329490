.boxWhiskerChart text {
  color: rgb(109, 109, 109);
  fill: rgb(109, 109, 109);
  font-size: 9pt;
  font-weight: 400;
}

.boxWhiskerChart .y.axis path,
.boxWhiskerChart .y.axis line,
.boxWhiskerChart .x.axis path,
.boxWhiskerChart .x.axis line {
  fill: none;
  stroke: #898989;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}

.boxWhiskerChart .grid path { stroke-width: 0;  }

.boxWhiskerChart .tick line {
  stroke: rgb(173, 173, 173);
  opacity: 0.6;
  stroke-dasharray: 2,1;
  stroke-width: 1;
  shape-rendering: crispEdges;
}

.boxWhiskerChart text.title,
.boxWhiskerChart text.xLabel,
.boxWhiskerChart text.yLabel {
  fill: rgb(101, 101, 101);
}

.boxWhiskerChart text.xLabel,
.boxWhiskerChart text.yLabel {
  font-size: 8pt;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.001ex;
}

.boxWhiskerChart text.title {
 font-size     : 14pt;
 font-weight   : 600;
 text-rendering: optimizeLegibility;
 letter-spacing: 0.001ex;
}

.d3-boxtip, .d3-outliertip {
  line-height: 1;
  padding: 6px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  z-index: 999999999;
}

.boxplot-help-term {
  font-weight: bold;
}

.boxplot-help-definition, .boxplot-help-footer {
  font-weight: 400;
}

.boxplot-help-footer {
  margin-top: 10px;
}

.boxWhiskerChart .chartTitle {
  display: flex;
  justify-content: center;
  color: rgb(101, 101, 101);
  font-size: 14pt;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  letter-spacing: 0.001ex;
}

@media screen and (max-width: 800px) {
  .boxWhiskerChart .chartTitle {
    font-size: 7pt;
  }
}

.boxWhiskerChart .chartTitle .help {
  margin-left: 5px;
}

.text-position {
  text-align: start;
}