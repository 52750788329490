progress {
	width: 400px;
	height: 14px;
	margin: 50px auto;
	display: block;
	/* Important Thing */
	-webkit-appearance: none;
	border: none;
}

progress::-webkit-progress-bar {
	background: black;
	border-radius: 50px;
	padding: 2px;
	box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.2);
}

progress::-webkit-progress-value {
	border-radius: 50px;
	box-shadow: inset 0 1px 1px 0 rgba(255, 255, 255, 0.4);
	background:
		-webkit-linear-gradient(45deg, transparent, transparent 33%, rgba(0, 0, 0, 0.1) 33%, rgba(0, 0, 0, 0.1) 66%, transparent 66%),
		-webkit-linear-gradient(top, rgba(255, 255, 255, 0.25), rgba(0, 0, 0, 0.2)),
		-webkit-linear-gradient(left, #ba7448, #c4672d);
	
	/* Looks great, now animating it */
	background-size: 25px 14px, 100% 100%, 100% 100%;
	-webkit-animation: move 5s linear 0 infinite;
}

@-webkit-keyframes move {
	0% {background-position: 0px 0px, 0 0, 0 0}
	100% {background-position: -100px 0px, 0 0, 0 0}
}

/* https://github.com/milworm/react-progress-2/blob/master/main.css */
.loader-60devs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 5px;
  width: 100%;
  z-index: 10000;
  opacity: 1;
  transition: opacity 250ms 250ms;
}

.loader-60devs .loader-60devs-progress {
  height: 2px;
  width: 100%;
  background-color: #F3BA00;
  transition-property: transform, opacity;
  transition-duration: 12s;
  transition-timing-function: cubic-bezier(0.04, 0.9, 0.11, 0.9);
  transform: translateX(-100%);
}

.loader-60devs[data-state="hidden"] {
  display: none;
  transition: none;
}

.loader-60devs[data-state="hidden"] .loader-60devs-progress {
  transition: none;
}

.loader-60devs[data-state="running"]::before {
  content: '';
  position: fixed;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loader-60devs[data-state="running"] .loader-60devs-progress {
  transform: translateX(0);
}

.loader-60devs[data-state="finishing"] {
  opacity: 0;
}

.loader-60devs[data-state="finishing"] .loader-60devs-progress {
  transform: scaleX(100) translateX(0);
}