
.button-component {
  background    : var(--btn-background-color);
  color         : var(--btn-text-color);
  padding       : 5px 10px;
  border-radius : 3px;
  min-width     : 10px;
  outline       : none;
  border       : none;
  cursor        : pointer;
  width         : max-content;
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis; 
  line-height   : 20px;
  height        : fit-content;
  font-weight   : 500;
  -webkit-appearance: none;
}

.button-component.noselectable {
  cursor: default;
}

.button-component.small {
  font-size: 10pt;
  line-height: 10px;
}

@media (max-width: 575.98px) { .button-component { font-size: 9pt; } .button-component.small { font-size: 6pt; } }
@media (min-width: 576px) { .button-component { font-size: 11pt; } .button-component.small { font-size: 8pt; } }

.button-component * {
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
}


.button-component:hover:not(.disabled):not(.noselectable) {
  background: var(--btn-highlight-color);
  color: var(--acublack);
}

.button-component.disabled {
  cursor: default;
}

.button-component:not(.primary).disabled, .button-component:not(.secondary).disabled {
  color: white;
}

.button-component.primary {
  background: var(--action-background-color);
  color     : var(--action-text-color);
}

.button-component.secondary {
  background: var(--action-second-background-color);
  color: var(--action-second-text-color);
}

.button-component.danger {
  background: var(--acured);
  color     : white;
}

.button-component.success {
  background: var(--medium-acugreen);
  color     : white;
}

.button-component.devel {
  background: rgb(173, 61, 61) !important;
  color: var(--acuwhite) !important;
}

.button-component.secondary.disabled {
  background: var(--disabled-action-second-background-color);
  cursor: default;
}

.button-component.primary.disabled {
  background: var(--disabled-action-background-color);
}

.button-component.primary:hover:not(.disabled) {
  background: var(--action-highlight-color);
}

.button-component.success:hover:not(.disabled) {
  background: var(--medium-acugreen);
  color: white;
}
.button-component.danger:hover:not(.disabled) {
  background: var(--acured);
  color: white;
}