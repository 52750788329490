@media (max-width: 992px){
    .NavigationFather > div > div > div > div{
        max-height: calc(100vh - 32px);
    }
    [class*='DrawerInner'] {
        height: calc(100vh - 32px);
    }
}

.header-nav-component {
  display: flex;
  flex-direction: column;
}

[class*='GlobalNavigationInner'] {
  background-color: var(--acublue) !important;
}

[class*='GlobalNavigationInner'] svg {
  fill: var(--acublue) !important;
}

[class*='ContainerNavigationInner'] {
  background-color: var(--acublue) !important;
} 

[class*='ContainerNavigationInner'] [class="navLink"] [class*='Item'] {
  background-color: var(--acublue) !important;
}

[class*='NavigationGlobalNavigationWrapper'] ~ [class*='NavigationContainerNavigationWrapper'] [class*='ContainerNavigationInner'] {
  background-color: #F4F5F7 !important;
}
[class*='NavigationGlobalNavigationWrapper'] ~ [class*='NavigationContainerNavigationWrapper'] .navLink.open * {
  color: #2F2F2F;
}

.topNavItem:hover, .bottomNavItem:hover {
  background-color: unset !important;
}

[data-role="droplistItem"]:hover, [data-role="droplistItem"]:hover * {
  background-color: var(--light-acugrey);
}

[class*='GlobalPrimaryActionsItemsWrapper'] [class*='styledContentContainer'] div {
  background-color: white !important;
} 

[class*="ContainerHeaderWrapper-"] > div {
  display: flex;
}

[class*="ContainerHeaderWrapper-"] > div > p {
  padding-top: 13px;
  font-weight: 400;
}

.navSideLink {
  cursor         : pointer;
  border-radius  : 70px;
  height         : 40px;
  width          : 40px;
  margin-left    : -3px;
  display        : flex;
  justify-content: center;
  align-items    : center;
  color: #FFFFFF;
}

.navSideLink svg { fill: var(--acublue) !important; }