#FAQ .filter {
  justify-content: center;
}

#FAQ .filter > *:first-child {
  margin-bottom: 30px;
}

#FAQ .sideImage img {
  max-width   : 150px;
} 

#FAQ .sideImage {
  width          : 45%;
  display        : flex; 
  align-items    : center;
  justify-content: center;
}

#FAQ .instructionImg img {
  max-width     : 45%;
  max-height    : 150px;
}

#FAQ .instructionImg, #FAQ .largeInstructionImg {
  width          : 45%;
  display        : flex; 
  align-items    : center;
  justify-content: center;
}

#FAQ .largeInstructionImg img {
  max-width   : 100%;
}

#FAQ .largeInstructionImg {
  width          : 75%;
}

#FAQ #GDPRImg {
  max-width   : 100px;
}

#FAQ h2 {
  margin-bottom: 50px;
}

@media (max-width: 600px) { #FAQ .sideImage { display: none; } }
@media (max-width: 900px) { #FAQ .instructionImage { display: none; } }

#FAQ .detailsBlockContent * {
  line-height: 1.5;
}

#FAQ .stepsIntro {
  margin-top: -25px;
  margin-bottom: 20px;
}