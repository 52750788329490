.schema-form-toggle-widget-root {
    width: 100%;
}
.schema-form-toggle-widget-content {
    width: 100%;
    display: flex;
    align-items: center;
    height: var(--schema-form-row-height);
}

.schema-form-toggle-widget-buttons{
    width: fit-content;
    outline: 2px solid var( --light-acugrey);
    padding: 1px;
    border-radius: 2px;
}

.schema-form-toggle-widget-info {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.schema-form-toggle-width {
   width: 90px; 
}
.schema-form-toggle-margin {
    margin-left: 1px;
}

.switch-component-disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}