@import './form.css';

.filter {
  vertical-align: middle;
}

.filter > input {
  min-width: 300px;
}

.quickfilters {
  display: flex;
  align-items: center;
}

.quickfilters .oneoffilter > * {
  display: inline;
}

.quickfilters .header {
  color: var(--header-text-color);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  width: auto;
}

@media (max-width: 575.98px) { .quickfilters .header { margin-right: 1px; } }
@media (min-width: 576px) { .quickfilters .header { margin-right: 4px; } }

@media (max-width: 499.98px) { .quickfilters * { font-size: 8pt; } }
@media (min-width: 500px) and (max-width: 575.98px) { .quickfilters * { font-size: 8pt; } }
@media (min-width: 576px) { .quickfilters * { font-size: 10pt } }

.quickfilter {
  color: var(--header-text-color);
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  font-style: normal;
  font-weight: 400;
  transition: opacity 0.3s ease 0s;
  opacity: 1;
  cursor: default;
  max-width: 100%;
  height: 100%;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  border-radius: 3px;
  border-width: 0px;
  display: flex;
  align-items: center;
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
}

.quickfilter.keepcase * {
  text-transform: none;
}

@media (max-width: 499.98px) { .quickfilter { margin: 0px 1px; line-height: 10px; } }
@media (min-width: 500px) and (max-width: 575.98px) { .quickfilter { margin: 0px 1px; line-height: 12px; } }
@media (min-width: 576px) { .quickfilter { margin: 0px 4px; line-height: 20px; } }

.quickfilter * {
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
}

.quickfilter > span {
  text-transform: uppercase;
}

.quickfilter select {
  background-color: transparent;
  border: none;
  width: max-content;
  padding: 0;
  outline: none;
}

@media (max-width: 499.98px) { .quickfilter select { height: 15px; font-size: 8pt; } }
@media (min-width: 500px) and (max-width: 575.98px) { .quickfilter select { height: 20px; font-size: 8pt; } }
@media (min-width: 576px) { .quickfilter select { height: 25px; font-size: 10pt; } }

.oneoffilter {
  display       : flex;
  align-items   : center;
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
}

.oneoffilter * {
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
}

.quickfilter:hover {
  background-color: var(--btn-highlight-color);
  color: var(--btn-text-color);
  cursor: pointer;
}

.quickfilter.selected {
  background-color: #eeeeee;
}
