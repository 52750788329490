:root {
  --details-icon-size: 40px;
  --details-large-value-size: 60pt;
  --details-medium-value-size: 20pt;
  --details-medium-counter-size: 25pt;
  --details-small-value-size: var(--font-size);
  --details-value-size: var(--font-size);
  --details-name-size: 10pt;
}

@media (max-width: 575.98px) { 
  :root {
    --details-large-value-size: 40pt;
    --details-medium-value-size: 14pt;
    --details-medium-counter-size: 20pt;
    --details-name-size: 6pt;
  }
}

.detailsContent.centered {
  text-align: center;
}

.detailsContent.vcentered {
  display: flex;
  align-items: center;
}

.detailsHeader {
  display: inline-flex;
  align-items: center;
  margin-bottom: 2px;
}

.details .help {
  margin-left  : 3px;
  margin-bottom: 3px;
  line-height  : unset;
  color        : var(--link-color);
}

@media (max-width: 480px) {
  .details .help {
    margin-left  : 0px;
  }
}

.details .icon {
  height: var(--details-icon-size);
  width: var(--details-icon-size);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.details .icon * {  
  color: var(--header-text-color);
  fill : var(--header-text-color);
}

.details .icon.small {
  height: calc(var(--details-icon-size) / 2);
  width: calc(var(--details-icon-size) / 2);
}

@media (max-width: 575.98px) { 
  .details .small-icon > span > svg { 
    height: calc(0.75 * var(--details-icon-size));
    width: calc(0.75 * var(--details-icon-size)); 
  } 
}
@media (min-width: 576px) { 
  .details .large-icon > span > svg { 
    height: calc(var(--details-icon-size));
    width: calc(var(--details-icon-size)); 
  }
}

.details .name {
  text-transform: uppercase;
  color: #999999;
  overflow      : hidden !important;
  text-overflow : ellipsis; 
  font-size: var(--details-name-size)
}

.details .value {
  color: rgb(47, 47, 47);
  font-weight: 300;
  margin: auto 0;
  line-height: 24px;
  vertical-align: middle;
  /*width: max-content; TODO: should use this width -> requires checking many affected UIs */
}

@media (max-width: 480px) {
  .details .value {
    line-height: unset;
  }
}

.details .value .button {
  max-width: 35vw;
  font-size: var(--fontSize);
}

.details .value.small {
  font-size: var(--fontSize) !important;
}

.details .value.medium {
  line-height: inherit;
}

.details .value.large {
  line-height: inherit;
}

.details .value.medium.counter { font-size: var(--details-medium-counter-size) }
.details .value.medium, .details .value.medium .button { font-size: var(--details-medium-value-size) } 
.details .value.large, .details .value.large .button { font-size: var(--details-large-value-size) } 

.details .value .react-datepicker-wrapper,input,select {
  width: 100%;
}

.details .withIcon {
  display: flex;
}

.details .withIcon > *:last-child {
  margin-left: 20px;
}

.details .button.withIcon > *:last-child, .details .nonselectable.withIcon > *:last-child {
  margin-left: 10px;
}

.details .withIcon .value .button {
  max-width: 25vw;
}