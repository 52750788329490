
#comment-block .box {
  margin-bottom: 24px;
  padding      : 20px;
  border       : 1px #e2e2e2 solid;
  border-radius: 5px;
  position     : relative;
  box-shadow   : 0px 2px 3px lightgrey;
  background-color: lightgrey;
}

#comment-block .header {
    margin-bottom: 8px;
    display: flex;
}

#comment-block .title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--header-text-color);
}

.text-area-xl {
   min-height: 400px !important;
   color: var(--dark-acugrey);
}

#comment-block .action-button {
    margin-left: 16px;
    display: flex;
}

#comment-block .placeholder {
  color: grey;
}

#comment-block .viewer {
  width: 100%;
  background-color: var(--light-acugrey);
  border-color: var(--transparent-medium-acugrey);
  border-width: 2px;
  padding: 10px 10px;
  margin-top: 10px;
  border-radius: 5px;
  border-style: solid;
  white-space: pre-line;
  overflow-wrap: anywhere;
  overflow: auto;
  min-height: 150px;
}

#comment-block .ml {
  margin-left: 8px;
}

#comment-block .full-size {
  height: 100vh;
}

#comment-block .textarea {
  color: var(--dark-acugrey);
}

.titleSection {
  display: flex;
  align-items: center;
  gap: 10px;
}