@import '../../style.css';


#ViewDiagnosis .horizontal-line {
  background-color: var(--light-acugrey);
}

#ViewDiagnosis .indexSeverity {
  display: flex;
  justify-content: center;
}

#ViewDiagnosis .indexSeverity * {
  width: max-content;
  min-width: 120px;
  padding: 2px 5px;
  border-radius: 5px;
}

#ViewDiagnosis .th {
  font-size: 15pt;
  font-weight: 800;
  text-transform: uppercase;
}

#ViewDiagnosis .help {
  margin-left: 3px;
  margin-top : 2px;
  line-height: 0;
}

#ViewDiagnosis .indexRow {
  background-color: var(--acuwhite);
  color: var(--font-color);
  font-weight: 600;
  padding: 5px;
  border-top: solid 1px var(--light-acugrey);
}

#ViewDiagnosis .indexRow:last-child {
  border-bottom: solid 1px var(--light-acugrey);
}

#ViewDiagnosis .indexRow:hover {
  background-color: #f5f5f5;
}

#ViewDiagnosis .indexRow.inconclusive {
  background-color: #b4cfdd;
  color: #004366;
}

#ViewDiagnosis .indexRow.inconclusive .button {
  background-color: #004366;
  color: white;
}

#ViewDiagnosis .indexRow.inconclusive .button:hover {
  background-color: #005d8b;
}

#ViewDiagnosis .indexRow.normal {
  background-color: rgb(235, 255, 215);
  color: rgb(55, 124, 38);
}

#ViewDiagnosis .indexRow.normal .button {
  background-color: rgb(55, 124, 38);
  color: white;
}

#ViewDiagnosis .indexRow.normal .button:hover {
  background-color: rgb(157, 223, 92);
}

#ViewDiagnosis .section.normal {
  fill: rgb(140, 206, 74);
}

#ViewDiagnosis .section.normal:hover {
  fill: rgb(170, 221, 120);
}

#ViewDiagnosis .indexRow.mild {
  background-color: rgb(252, 243, 191);
}

#ViewDiagnosis .indexRow.mild .button,
#ViewDiagnosis .indexRow.normal_mild .button {
  background-color: rgb(128, 122, 35);
  color: white;
}

#ViewDiagnosis .indexRow.mild .button:hover,
#ViewDiagnosis .indexRow.normal_mild .button:hover {
  background-color: rgb(218, 194, 63);
}

#ViewDiagnosis .indexRow.mild,
#ViewDiagnosis .indexRow.normal_mild {
  color: rgb(128, 122, 35);
}

#ViewDiagnosis .indexRow.normal_mild {
  background-image: linear-gradient(to right, rgb(235, 255, 215), rgb(252, 243, 191));
}

#ViewDiagnosis .section.mild {
  fill: rgb(252, 221, 45);
}

#ViewDiagnosis .section.mild:hover {
  fill: rgb(252, 236, 150);
}

#ViewDiagnosis .indexRow.moderate {
  background-color: rgb(252, 220, 191);
}

#ViewDiagnosis .indexRow.moderate .button,
#ViewDiagnosis .indexRow.mild_moderate .button {
  background-color: rgb(129, 91, 35);
  color: white;
}

#ViewDiagnosis .indexRow.moderate .button:hover,
#ViewDiagnosis .indexRow.mild_moderate .button:hover {
  background-color: rgb(252, 172, 97);
}

#ViewDiagnosis .indexRow.moderate,
#ViewDiagnosis .indexRow.mild_moderate {
  color: rgb(129, 91, 35);
}

#ViewDiagnosis .indexRow.mild_moderate {
  background-image: linear-gradient(to right, rgb(252, 243, 191), rgb(252, 220, 191));
}

#ViewDiagnosis .section.moderate {
  fill: rgb(235, 164, 57);
}

#ViewDiagnosis .section.moderate:hover {
  fill: rgb(240, 190, 114);
}

#ViewDiagnosis .sectionHeader .actions {
  margin-left: auto;
}

#ViewDiagnosis .indexRow.severe {
  background-color: rgb(252, 191, 191);
}

#ViewDiagnosis .indexRow.severe .button,
#ViewDiagnosis .indexRow.moderate_severe .button {
  background-color: rgb(112, 31, 31);
  color: white;
}

#ViewDiagnosis .indexRow.severe .button:hover,
#ViewDiagnosis .indexRow.moderate_severe .button:hover {
  background-color: rgb(255, 88, 88);
}

#ViewDiagnosis .indexRow.severe,
#ViewDiagnosis .indexRow.moderate_severe {
  color: rgb(112, 31, 31);
}

#ViewDiagnosis .indexRow.moderate_severe {
  background-image: linear-gradient(to right, rgb(252, 220, 191), rgb(252, 191, 191));
}

#ViewDiagnosis .section.severe {
  fill: rgb(235, 57, 57);
}

#ViewDiagnosis .section.severe:hover {
  fill: rgb(233, 128, 128);
}

#ViewDiagnosis .severityChartBlock {
  background-color: var(--acuwhite);
  color: var(--font-color);
}

#IndexHelp .indexDescription {
  text-transform: none;
  font-weight: 400;
  max-width: 400px;
}

#IndexHelp svg {
  color: var(--acublue);
}

#IndexHelp .footnote {
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 10px;
  max-width: 460px;
}

#IndexHelp .footnote .icon {
  width: 24px;
}

#IndexHelp .note {
  font-style: italic;
}

#IndexHelp .note:not(:last-child) {
  margin-bottom: 5px;
}

#IndexHelp .indexName {
  font-weight: bold;
  margin-right: 10px;
  min-width: max-content;
  width: 30px;
}

.toolptip-alignment {
  display: flex;
  justify-content: flex-start;
}

.toolptip-alignment.warning {
  background-color: transparent !important;
}

#ViewDiagnosis #chart-index-svg .text {
  fill: white;
  font-weight: 800;
  font-size: 9pt;
}

@media (max-width: 500px) {
  #ViewDiagnosis .th {
    font-size: small;
  }
}

@media (max-width: 375px) {
  #ViewDiagnosis .th {
    font-size: 7.5pt;
  }

  #ViewDiagnosis [class*="odi"] {
    font-size: 7pt;
  }

  #ViewDiagnosis .grid-col > *:not(:first-child) {
    margin-left: 0px;
  }
}

#ViewDiagnosis .odi3Rows, #ViewDiagnosis .odi4Rows {
  margin-top: 12px;
  margin-bottom: 12px;
}

#ViewDiagnosis .mt , #ViewDiagnosis .severityChartBlock{
  margin-top: 12px;
}

#ViewSummary .details .name {
  font-weight: 500;
}

.diagnosis-children {
  width: 100%;
  height: 100%;
}

#ViewDiagnosis #BodyPosition table {
  margin-bottom: 0;
}