.study-filters-root {
  width: 100%;
}
.study-filters-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 800px) {
  .study-filters-row {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 801px)  {
  .study-filters-row {
    grid-template-columns: 1fr 1fr;
  }
}


.study-filters-row-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 800px) {
  .study-filters-row-2 {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 801px)  {
  .study-filters-row-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.study-filters-row-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

@media screen and (max-width: 800px) {
  .study-filters-row-3 {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 801px)  {
  .study-filters-row-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.study-filters-children {
  width: 100%;
}

.study-filters-margin {
  margin-top: 14px;
}