#EditUser .settings {
  align-items: center;
  justify-content: space-between;
}

#EditUser .settings > .toggleSelect.settingValue {
  width: unset;
}

#EditUser .settings > li {
  width: unset;
  max-width: 75%;
}

#EditUser .settings > *:not(li) {
  max-width: 25%;
}

#EditUser li .help {
  margin-left: 5px;
  display: inline;
  color: var(--acublue);
}


#EditUser li  {
  display:flex;
}