span[class*="ItemParts__Before"] { /* class used for nav item icon wrapper element */
  margin-right: 5px;
}

.navLink.open * {
  color: #2f2f2f;
}

.navLink:hover {
  color: #2f2f2f;
  text-decoration: underline;
}

[class*="DrawerContent-"] .navLink:not(.open) * {
  color: #2f2f2f;
}

[class*="DrawerContent-"] .navLink:not(.active) [class*='Item'], [class*="DrawerMain-"] .navLink:not(.active) [class*='Item']:hover {
  background-color: inherit;
}

.navLink.open [class*='Item'] svg {
  color: #2f2f2f;
  fill: #2f2f2f;
}

.navLink:not(.open) [class*='Item'] svg {
  color: #FFFFFF;
  fill: #FFFFFF;
}

.navLink:not(.active) [class*='Item'], .navLink:not(.active) [class*='Item']:hover {
  background-color: #F4F5F7;
}

.navLink.open.active [class*="Item-"] {
  background-color: var(--acugrey);
}

.navLink.active [class*="Item-"] {
  background-color: rgba(9, 30, 66, 0.25);
}