:root {
  /** Colors -> All app colors should be defined here (this will be mainly corporative colors) **/
  
  --light-acugreen: #84b489;
  --medium-acugreen: #19AB27;

  --dark-acublue: #2f2f2f;
  --light-acublue: #b4cfdd;
  --transparent-light-acublue: rgba(3, 89, 136, 0.302);
  --acublue: #004366;

  --dark-acugrey: #2f2f2f;
  --medium-acugrey: rgb(122, 122, 122);
  --transparent-medium-acugrey: rgba(122, 122, 122, 0.4);
  --light-acugrey: #eaebec;
  --transparent-light-acugrey: #eaebecc4;
  --acugrey: lightgrey;

  --dark-acuyellow: #cca31d;
  --transparent-light-acuyellow: rgba(243, 186, 0, 0.2);
  --light-acuyellow:#FFF1CF;
  --acuyellow: #f3ba00;

  --light-acuorange: #ba7448;
  --acuorange: #c4672d;
  
  --transparent-light-acured: rgba(245, 110, 110, 0.1);
  --light-acured: rgb(245, 110, 110);
  --acured: red;
  --dark-acured: rgb(207, 7, 7);

  --acuwhite: white;
  --acublack: rgba(0, 0, 0, 0.781);

  /** Element Colors -> Ideally should reference one of the above colors **/  
  --disabled-action-background-color: var(--transparent-light-acuyellow);
  --disabled-action-second-background-color: var(--transparent-light-acugrey);
  --action-background-color: var(--acuyellow);
  --action-second-background-color: var(--medium-acugrey);
  --action-highlight-color: var(--light-acuyellow);
  --action-text-color: var(--dark-acublue);
  --action-second-text-color: var(--acuwhite);

  --link-color: #2c91c7;

  --disabled-btn-text-color: var(--acuwhite);
  --btn-background-color: var(--light-acugrey);
  --btn-highlight-color: var(--acugrey);
  --btn-text-color: var(--dark-acublue);

  --details-text-color: var(--medium-acugrey);

  --error-background-color: var(--light-acured);
  --error-text-color: var(--acuwhite);
  --error-border-color: var(--dark-acured);

  --warning-background-color: var(--transparent-light-acuyellow);
  --warning-border-color: var(--acuyellow);

  --info-icon-color: var(--acublue);

  --sw-background-color: var(--light-acuyellow);
  --sw-border-color: var(--acuyellow);

  --font-color: var(--dark-acublue);
  --header-text-color: var(--font-color);
}

.red {
  color: var(--acured);
}