.ceMark {
  display        : flex;
  justify-content: center;
  flex-direction : column;
}

 #About h3 {
   margin-bottom: 5px;
 }

 #About .details.version {
  display: flex;
  flex-direction: column;
  justify-content: center;
 }

 #About .aboutImage {
   margin      : 0 50px;
   max-width   : 200px;
   align-self  : center;
   justify-self: center;
 }

 #About .details .withIcon > *:first-child {
   margin-right: 15px;
 }

 #About .aboutText, #About .aboutText * {
   line-height: 1.5;
 }

 @media (max-width: 900px) { #About .aboutImage { display: none; } }