
#BatchSummary {
  display              : grid;
  grid-template-columns: auto auto auto;
  vertical-align       : middle;
  text-align           : center;
  justify-items        : center;
  row-gap              : 5px;
  padding              : 1.2em;
  margin-bottom        : 1em;
  border               : 1px #e2e2e2 solid;
  border-radius        : 5px;
}

#BatchSummary .title {
  text-transform: uppercase;
  color: #999999;
  font-size: 0.8em;
  margin-bottom: 2px;
  margin-right: 0;
}

#BatchSummary .status {
  width: 70px;
  width: max-content;
  line-height: 25px;
  /* justify-content: center; */
}

#BatchSummary > .line {
  grid-column: 1 / span 3;
  border-top: 1px solid rgb(226, 226, 226);
  margin-top: 5px;
  margin-bottom: 5px;
}

#BatchSummary  button {
  background-color: rgb(234, 235, 236);
  color:rgb(80, 95, 121) !important;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  width: 80px;
}

#total.status {
  font-weight: bold;
  font-size: 12px;
}