#Error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  max-width: 100vw;
}

#Error > * {
  max-width: 800px;
}

#Error .icon {
  max-width: 125px;
}

@media (max-width: 575.98px) {
  #Error .icon { max-width: 62px; }
}

#Error .icon svg {
  color: var(--dark-acublue);
  fill: white;
}

@media (max-width: 900) {
  #Error #WarningIcon { width: 62px; height: 62px; }
}

@media (min-width: 900) {
  #Error #WarningIcon { width: 124px; height: 124px; }
}