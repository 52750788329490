.calendarInput .icon svg * {
  fill : var(--dark-acugrey) !important;
  color: var(--dark-acugrey) !important;
}

.calendarInput .react-datepicker-wrapper {
  width: 100%;
}

.calendar-custom-header-root{
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-custom-header-disabled {
  cursor: not-allowed;
  pointer-events: none;
  color: var(--acugrey);
}

.calendar-custom-header-cursor {
  cursor: pointer;
}


.calendar-container-root {
  display: flex;
}

.calendar-container-block {
  background: #f0f0f0;
  width: 140px;
  display: grid;
  grid-template-columns: 1fr;
  padding: 16px;
  align-items: center;
}

.calendar-container-title {
  font-size: 12px; 
  font-weight: 600;
  text-transform: uppercase;
}

.calendar-options {
  cursor: pointer;
  user-select: none;
  font-size: 13px;
}

.calendar-options:hover {
  text-decoration: underline;
}

.calendarInput.disabled {
  cursor: not-allowed;
}

.calendarInput-disabled  {
  pointer-events: none;
  background: var(--light-acugrey);
}