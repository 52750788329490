

#ViewSignals {
  max-width: unset;
}

#ViewSignals .warning, #ViewSignals .error {
  align-items: center;
  background-color: var(--warning-background-color);
  border-color: var(--warning-border-color);
}

#ViewSignals .warning .content > *:first-child {
  width : 50px;
  height: 50px;
  color : var(--warning-border-color);
  fill  : var(--acuwhite);
}

#ViewSignals .error .content > *:first-child {
  width : 50px;
  height: 50px;
  color : var(--error-border-color);
  fill  : var(--acuwhite);
}

#ViewSignals .error .message {
  font-style: italic;
  font-weight: bold;
}

#ViewSignals .content .title {
  text-transform: none;
  margin-top    : 10px;
}