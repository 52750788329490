#pdf {
	max-width: 800px;
	height: 85vh;
  margin: 2em 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media print {
  #pdf {
    display: none;
  }
}