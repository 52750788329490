
.status {
  border-radius: 4px;
  display: inline-block;
  font-size: 9pt;
  font-weight: bold;
  min-width: 80px;
  padding: 0.01em 0.5em;
  text-align: center;
  text-transform: uppercase;
}

.status.counter {
  min-width: unset;
}

.status.created {
  background-color: #FFF1CF;
  color: #8c836e;
}

.status.in-stock, .status.ready {
  background-color:#CCF1F1;
  color:#708686;
}

.status.active {
  background-color: #D2E5EF;
  color:#7f8c94;
}

.status.disabled, .status.retired {
  background-color: #FFDEE5;
  color: #9a8489;
}