#PageContainerViewEvents {
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: rgb(219, 209, 225);
  background-color: #fffee2;
}

#ViewEvents .eventType {
  display: inline-flex;
  align-items: center;
}

#ViewEvents .eventType .icon {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}