
input, input[type='tel'].phone, .dateTimePickerInput, textarea {
  background-color: #FAFBFC;
  border-color: #DFE1E6;
  border-radius: 3px;
  border-style: solid;
  color: #091E42;
  border-width: 2px;
  padding: 6px 6px;
}

@media (max-width: 575.98px) { input, input[type='tel'], .dateTimePickerInput input { font-size: 8pt; line-height: 20px; } }
@media (min-width: 576px) { input, input[type='tel'], .dateTimePickerInput input { font-size: 11pt; line-height: 29px; } }

input:hover:not(:focus), textarea:hover:not(:focus), .dateTimePickerInput:hover:not(:focus) {
  background-color: rgb(235, 236, 236);
}

input:focus, textarea:focus, .dateTimePickerInput:focus {
  border-color: rgb(76, 154, 255);
  outline: none;
}

/*-------- atlaskit select ----------------------*/

.react-tel-input .search-box {
  width: 85%;
}

.react-tel-input {
  height: 45px;
}

.react-tel-input input[type='tel'] {
  width: 100% !important;
  height: 100% !important;
  line-height: 29px !important;
  border-radius: 3px !important;
  border-width: 2px !important;
  background-color: #FAFBFC !important;
  border-color: #DFE1E6 !important;
  padding-left: 48px !important;
}

.react-tel-input .flag-dropdown {
  border-width: 2px !important;
  border-color: #DFE1E6 !important;
}

.react-tel-input input[type='tel']:focus ~ * {
  border: 2px solid rgb(76, 154, 255) !important;
  outline: none;
}

.react-tel-input input[type='tel']:focus {
  box-shadow: none !important;
  border: 2px solid rgb(76, 154, 255) !important;
}

.react-tel-input input[type='tel']:hover:not(:focus) {
  background-color: rgb(235, 236, 236) !important;
}

.react-tel-input .selected-flag {
  /*position: inherit !important;*/
  z-index: inherit !important;
  display: flex;
  align-items: center;
}

.react-tel-input .selected-flag .flag {
  position: inherit !important;
  top: inherit !important;
  margin-top: 0 !important;
}

/*-----------------------------------------------*/

/*-------- react-datepicker ----------------------*/

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__day--outside-month {
  pointer-events: none;
  color: var(--light-acugrey) !important;
}

/*-----------------------------------------------*/

.dateTimePickerInput {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.dateTimePickerInput .rc-time-picker-input {
  font-size : inherit;
  transition: none;
}

.dateTimePickerInput .icon {
  line-height: 100%;
  width: unset !important;
  height: unset !important;
}

@media (max-width: 500px) {
  .dateTimePickerInput .icon > span, .dateTimePickerInput .icon svg {
    width: 20px;
    height: 20px;
  }
}

.dateTimePickerInput input {
  background-color: inherit !important;
  border: none !important;
  padding: 0 !important;
}

.dateTimePickerInput *:hover {
  background-color: inherit;
}

.mandatory {
  color: var(--error-border-color);
}

#type.field {
  color: rgb(112, 112, 112);
  font-size: 12px;
  height: 36px;
  line-height: 32px;
  margin: 2px;
  padding: 2px;
  text-align: left;
  width: 300px;
  border-color: rgb(226, 226, 226);
  border-style: solid;
  border-radius: 5px;
}

textarea.field {
  display: block;
  width: 100%;
  border-color: rgb(226, 226, 226);
  border-style: solid;
  border-radius: 5px;
  height: 200px;
  padding: 6px;
  margin: 1px;
}

select:not([multiple]) {
  font-size       : 14px;
  height          : 45px;
  padding         : 6px;
  background-color: #FAFBFC;
  border-color    : #DFE1E6;
  color           : #091E42;
  border-radius   : 3px;
  border-width    : 2px;
  box-sizing      : border-box;
}

/*-------- atlaskit select ----------------------*/

.akselect__control {
  min-height      : 34px;
  padding         : 6px;
  border-color    : #DFE1E6 !important;
  background-color: #FAFBFC !important;
  border-radius   : 3px;
  border-width    : 2px;
  box-sizing      : border-box;
}

.akselect__control--is-focused {
  border-color: rgb(76, 154, 255) !important;
}

.akselect__value-container {
  padding-left    : 6px !important;
}

.akselect__control:hover:not(:focus) {
  background-color: rgb(235, 236, 236) !important;
}

@media (max-width: 575.98px) { 
  .akselect__control {
    font-size: 8pt;
  }
}
@media (min-width: 576px) {
  .akselect__control {
    font-size: 11pt;
  }
}
/*------------------------------------------------*/

@media (max-width: 575.98px) {
  .react-toggle-track {
    width: 35px !important;
    height: 15px !important;
  }
  .react-toggle-track-check {
    left: 4px !important;
    top: 0px !important;
  }
  .react-toggle-track-check, .react-toggle-track-check svg {
    width: 13px !important;
    height: 7px !important;
  }
  .react-toggle-track-x {
    top: 0px !important;
    right: 5px !important;
  }
  .react-toggle-track-x, .react-toggle-track-x svg {
    width: 10px !important;
    height: 7px !important;
  }
  .react-toggle-thumb {
    width: 15px !important;
    height: 15px !important;
    top: 0px !important;
  }
  .react-toggle--checked .react-toggle-thumb {
    left: 19px !important;
  }
}

select.unselected {
  color        : rgb(112, 112, 112);
}

.selected {
  border-color: rgb(76, 154, 255);
}

textarea::-webkit-input-placeholder {
  color: rgb(112, 112, 112);
}