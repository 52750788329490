#UsageReport .Chart {
  border-style : solid;
  border-radius: 5px;
  border-width : 1px;
  border-color : rgb(226, 226, 226);
  padding      : 20px;
  margin-top   : 10px;
}

#UsageReport .detailsContent .button {
  margin    : auto;
  margin-top: 5px;
  padding   : 8px;
}