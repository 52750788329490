#PositionChart .sub-category span.position-label {
    padding-left: 10px;
}

#PositionChart .sub-category {
    background-color: #fef2c7;
}

#PositionChart [class*="category-"] span.position-label {
    padding-left: 10px;
    font-weight: 600;
}

#PositionChart .category-supine {
    background-color: #ffce50;
}

#PositionChart .category-non_supine {
    background-color: #ffe797;
}