.serviceworker {
  position           : fixed;
  top                : 0;
  padding            : 10px;
  background-color   : var(--sw-background-color);
  border-bottom-color: var(--sw-border-color);
  border-bottom-width: 5px; 
  border-bottom-style: solid;
  width              : 100vw;
  text-align         : center;
  text-decoration    : none;
  min-height         : 5%;
  animation-duration : 1s;
  animation-fill-mode: both;
  z-index            : 9999;
}

.serviceworker .warningIcon {
  width: 50px;
}

.serviceworker .warningIcon svg {
  width: 50px;
  height: 50px;
}