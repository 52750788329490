.checkbox-component {
  color: var(--acublack);
  display: flex;
  align-self: center;
}

.checkbox-component  > span {
  font-size: 14px;
  color: var(--acublack);
}
.checkbox-component input {
  margin-left: 8px;
  width: 16px;
  cursor: pointer;
}


.checkbox-component input:checked {
    accent-color: var(--acublack);
}