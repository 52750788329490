.legend {
  display: flex;
  justify-content: flex-end;
}

.legend > .title {
  color       : #172b4d;
  font-family : -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size   : 14px;
  font-style  : normal;
  font-weight : 400;
  text-align  : left;
  width       : auto;
  margin-right: 4px;
}

.legend .items {
  display: inline;
}

.legend * {
  float         : left;
  vertical-align: middle;
  margin-left   : 6px;
}

.tooltipLegend .title {
  font-size: inherit;
}

.tooltipLegend .statusItems {
  margin-left: 5px;
  align-items: flex-start;
}

.tooltipLegend .CREATED {
  border: 1px solid black;
}

@media (max-width: 575.98px) {
  .legend {
    font-size: 9px;
  }

  .legend > .title {
    font-size: 11px;
  }
}
