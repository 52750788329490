.chart-gauge .section:hover {
  font-weight: bold;
}

.chart-gauge .section .label {
  font-size: 8pt;
  font-weight: bold;
  fill: white;
}

.chart-gauge .snoring-level-text {
  font-size: 9px;
  font-weight: bold;
  fill: white;
}

.chart-gauge .section .min-val {
  font-size: 8pt;
}

.chart-gauge .needle, .chart-gauge .needle-center {
  box-shadow: #464A4F;
}

.chart-gauge .needle {
  fill: #464A4F;
}

.chart-gauge .value-indicator {
  fill: #464A4F;
}

.chart-gauge .needle-center {
  stroke: #464A4F;
  stroke-width: 3;
  fill: white;
}

.chart-gauge .section:hover .arc {
  fill-opacity: 0.5;
}

