.autocomplete-component  {
   width: 100%;
}

.autocomplete-component-block {
  margin-top: 10px;
  cursor: pointer;
}

.autocomplete-component-opts-block-label {
    width: 100%;
}

.autocomplete-opt {
  color: var(--acublack);
  font-weight: 400;
  line-height: 20px;
}