#Spinner {
  position: fixed;
  z-index: 999;
  height: 96px;
  width: 96px;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

#Spinner svg * {
  stroke: var(--acuyellow)
}

#Spinner .text {
  font-weight: 800;
  color: rgb(236, 236, 236);
  text-shadow: 1px 1px 5px rgb(151, 151, 151); 
  font-size: 14pt;
  width: max-content;
  display: inline-block;
}

.loading-alternative{
  width: 100%;
  height: 100%;
  background: var(--acublue);
  position: absolute;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}