#ViewUser .editField svg * {
  fill : var(--acugrey);
  color: var(--acugrey);
}

#ViewUser .editField:hover svg * {
  fill : var(--dark-acugrey);
  color: var(--dark-acugrey);
}

.maxContent {
  width: max-content;
}