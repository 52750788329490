@import './variables.css';

.schema-form-theme.integer, 
.schema-form-theme.string, 
.schema-form-theme-check {
    display: grid;
    grid-template-columns: 20fr 8fr 1fr;
    grid-template-rows: var(--schema-form-row-height);
    align-items: center;
}

.schema-form-align-end {
    display: flex;
    justify-content: flex-end;
}

.schema-form-theme.integer input {
    max-width: 100px;
}

.schema-form-theme.string label, .schema-form-theme.integer label {
    margin: 0;
}

.schema-form-theme.string .required, .schema-form-theme.integer .required {
    color: var(--acured);
}

.form-group.field.field-boolean,
.form-group.schema-form-theme {
    margin-bottom: 0;
}