.dropHeader {
  display: inline-flex;
  align-items: center;
}

.dropHeaderName {
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 2px;
  overflow      : hidden !important;
  text-overflow : ellipsis; 
  font-size: var(--details-name-size)
}

.dropDestination {
    border: dashed;
    border-color: #999999;
    border-width: 3px;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    font-weight: normal;
    font-size: larger;
    color: #999999;
}

.dropDestination .dropButton {
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    font-size: smaller;
    padding: 5px 10px;
    width: 25%;
    margin: 0% auto;
    cursor: pointer;
}

.dropDestination .annotation {
    font-size: small;
    font-weight: normal;
}

.highlight {
  border-style: solid;
  background-color: #FAFAFA;
}

.dropDestination.disabled {
  color: lightgray;
  border-color: lightgray;
  cursor: no-drop;
}