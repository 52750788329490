
#Navigation {
  position: sticky;
  left    : 0px;
  z-index : 2;
}

#Navigation .hidden {
  transition: transform 0.3s;
  transform : translateX(-1080px);
}

.pageContainer {
  flex      : 1 1 auto;
  position  : relative;
  z-index   : 1;
  min-width : 0;
  width     : 100%;
  height    : 100%;
  max-height: 100%;
  overflow  : auto;
}