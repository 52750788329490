.viewStudy-helper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.viewStudy-helper .test-block {
    display: flex;
}

.viewStudy-helper .test-block-margin {
    margin-top: 20px;
}


.viewStudy-helper .retries-block-title {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.viewStudy-helper .content {
    align-items: center;
}



.viewStudy-helper .dashed {
    width: 100%;
    border-bottom: 2px dashed #000;
    height: 10px;
    margin-bottom: 24px;
}

.viewStudy-helper .dashed-color-ANALYSED, .dashed-color-INVALID {
    border-bottom: 2px dashed #fff !important;
}

.viewStudy-helper .dashed-title-color-ANALYSED, .dashed-title-color-INVALID {
   color: #fff !important;
}

.viewStudy-helper .dashed-title {
    min-width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    color: #000;
    text-transform: uppercase; 
    font-weight: 600;
}

.icon-tooltip {
    height: fit-content;
}

.icon-tooltip :first-child {
    background: transparent !important;
}

.viewStudy-helper .tooltip-message {
    word-break: break-word;
    max-width: 400px;
}
