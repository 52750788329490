#filter-option.root {
    margin-top: 12px;
}

#filter-option .switch-filter {
    display: flex;
    padding-left: 16px;
}

#filter-option .title {
    text-transform: uppercase;
    color: var(--acublack);
    font-weight: 400;
}

#filter-option .title:not(:first-child) {
   margin-top: 24px;
}


#filter-option .option {
    margin-top: 16px;
    margin-right: 16px;
    border: 2px solid var(--medium-acugrey);
    border-radius: 14px;
    color: var(--acublack);
    font-weight: 500;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    cursor: pointer;
}

#filter-option .option.selected {
    background: var(--acublue);
    color: white;
}

#filter-option .option.sm {
    width: fit-content;
}

#filter-option .option.lg {
    width: 250px;
}
