#RegisterBatch .instructions {
  margin-top: 20px;
  margin-bottom: 20px;
}

#ReviewBatch .errors {
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 10px;
  margin-bottom: 30px;
  visibility: inherit;
}

#ReviewBatch .errors .sub-title {
  color: rgb(23, 43, 77);
  display: inline;
  font-size: 18px;
  font-weight: 400;
}

#ReviewBatch .errors * {
  display: inline;
}

#ReviewBatch .errors > .section {
  grid-column: 1 / span 4;
  margin-bottom: 4px;
}

#ReviewBatch .errors > div.line {
  grid-column: 1 / span 4;
  border-top: 1px solid rgb(226, 226, 226);
  margin-top: 5px;
  margin-bottom: 5px;
}
