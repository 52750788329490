.react-bootstrap-table-pagination li + li {
  margin-top: 0; /* This is an error comming from bootstrap 4*/
}

.react-bootstrap-table-pagination .page-link:focus {
  box-shadow: none;
}

.react-bootstrap-table-pagination .page-link {
  color: #7a7a7a;
}

.react-bootstrap-table th:focus {
  outline: none;
}

@media (max-width: 575.98px) { 
  .react-bootstrap-table-pagination { font-size: 8pt; }
  .react-bootstrap-table-pagination .page-link { padding: 8px; }
  .react-bootstrap-table-pagination > div { width: 50%; }
}

.react-bootstrap-table-pagination .active .page-link {
  background-color: #7a7a7a;
  border-color: #7a7a7a;
  color: white;
}

.react-bootstrap-table td, .react-bootstrap-table td span {
  font-size: 18px;
  font-weight: 250;
  white-space: break-spaces;
}

.react-bootstrap-table thead th, .react-bootstrap-table tbody td, .react-bootstrap-table tbody tr {
  border: none;
}

/* .react-bootstrap-table thead th, .react-bootstrap-table .link-row:last-child {
  border-bottom: 2px solid #D3D3D3;
} */

.react-bootstrap-table tbody td {
  vertical-align: middle;
}

.react-bootstrap-table .table-hover tr:hover {
  background-color: rgba(0,0,0,0);
}

.react-bootstrap-table .link-row {
  cursor: pointer;
  height: 50px;
  box-sizing: border-box;
}

.react-bootstrap-table .table-hover .link-row:hover {
  background-color: rgba(0,0,0,0.075);
  outline: thin solid #D3D3D3;
}

.react-bootstrap-table .table-hover .link-row > td:first-child:not(.selection-cell) {
  padding-left: 20px;
}

.react-bootstrap-table .table-hover .link-row > td:last-child {
  padding-right: 20px;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.page-link {
  cursor: default;
} 

.table-header { color: var(--text-header-color); }
.table-header .action { width: 65px; }

.action { font-weight: 500; min-width: fit-content; }

@media (max-width: 575.98px) { 
  .table-header { font-size: 6pt; font-weight: 400; } 
  th {
    max-width     : 50px;
    white-space   : nowrap;
    overflow      : hidden !important;
    text-overflow : ellipsis;
  }
  td, td span { font-size     : 8pt; } 
}
@media (min-width: 576px) { 
  .table-header { font-size: 10pt; font-weight: 600; } 
  td, td span { font-size: 10pt; } 
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerWithHelp {
  display: inline-flex;
}

.headerWithHelp > .help {
  margin-left: 5px;
  line-height: 0;
}

.HelpTooltip.headerHelp {
  text-transform: none;
  font-weight: initial;
  font-size: var(--fontSize);
}

.react-bootstrap-table-pagination-total {
  display: inline-flex;
}