.menuButton {
  position: relative;
}

.menuButton .menu {
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 40px;
  width: max-content;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 100;
}

.menuButton .menu > * {
  width: 100%;
  border-radius: 0;
}

.menuButton .menu > *:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.menuButton .menu > *:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.menuButton .menu > *:not(:first-child) {
  border-top: solid 1px rgb(178, 178, 178);
}

.menuButton .menu.showMenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}