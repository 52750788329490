.page {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
  padding-top  : 26px;
  box-sizing   : content-box;
  padding: 20px;
}

.pageContent {
  margin-bottom: 2em;
}

.fullscreen .page {
  max-width: unset;
}

.page * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.page h1, .page h2, .page h3, .page h4 {
  color: var(--text-header-color) ;
}

.pageHeader {
  display: flex;
  flex-direction: row;
  line-height: 30px;
  margin-bottom: 30px;
}

.pageHeader h1 {
  margin: 0;
}

.pageHeader .title {
  color         : var(--header-text-color);
  font-weight   : 600;
  margin-right  : 30px;
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis;
  display       : flex;
  flex-direction: column;
}

.pageHeader .title::after {
  content   : '';
  background: var(--acuyellow);
  height    : 3px;
  width     : 50px;
  margin-top: 10px;
}

/*.headerBreak {
  background: var(--acuyellow);
  height: 3px;
  width: 6em;
  
  margin-top: 10px;
}*/

.pageHeader .title.wraptext {
  white-space   : normal;
}

@media (max-width: 575.98px) { .pageHeader .title, .page h1 { font-size: 16pt } }
@media (min-width: 576px) { .pageHeader .title, .page h1 { font-size: 20pt } }

@media (max-width: 575.98px) { .page h2 { font-size: 12pt } .page h3 { font-size: 11pt } .page h4 { font-size: 10pt } .page h5 { font-size: 9pt } }
@media (min-width: 576px) { .page h2 { font-size: 16pt } .page h3 { font-size: 14pt } .page h4 { font-size: 12pt } .page h5 { font-size: 10pt } }

.page .actions, .serviceworker .actions {
  display        : flex;
  justify-content: flex-end;
}

.page .actions.left, .serviceworker .actions.left {
  justify-content: flex-start;
}

.page .actions > *, .serviceworker .actions > * {
  margin-left: 6px;
}

.page .actions > *:first-child, .serviceworker .actions > *:first-child {
  margin-left: 0;
}

.page .items {
  display: inline-block;
}

.page .items > * {
  float: left;
}

.page .items.separated > * {
  margin-right: 5px;
}

.pageSection {
  margin-bottom: 30px;
}

.pageSection .sectionHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 30px;
  margin-bottom: 0.5em;
}

.pageSection .sectionHeader .title {
  margin-bottom: 0em;
}

.pageSection .title {
  color: var(--header-text-color);
  font-size: var(--titleSize);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.pageSection > .title > * {
  margin-right: 10px;
}

.pageSection > .title > *:last-child {
  margin-right: 0px;
}

.pageSection > .title.large {
  font-size: calc(var(--titleSize) + 6pt);
  font-family: sans-serif;
}

.pageSubsection {
  margin-left: 10px;
}

.pageSubsection .title {
  color: #999999;
  font-size: calc(var(--titleSize) - 2pt);
}

.page a {
  text-decoration: none !important;
  outline: none !important;
}

.page .separator {
  width: 100%;
  height: 1px;
  background-color: var(--acugrey)
}

.pageFooter {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.page .indented {
  margin-left: 5px;
}

.page .exportFrame {
  width: 100%;
  height: 88vh; 
}

.page .placeholder {
  color: var(--acugrey)
}

.page .label {
  text-transform: uppercase;
  color: #999999;
  margin-bottom: 2px;
  white-space   : nowrap;
  overflow      : hidden !important;
  text-overflow : ellipsis; 
  
}

.page .horizontal-line {
  height: 2px;
  width: 100%;
  background-color: var(--dark-acugrey);
}

.page .inline {
  display: flex;
}

.page .inline.centered {
  justify-content: center;
}

@media (max-width: 575.98px) { .page .label { font-size: 6pt } }
@media (min-width: 576px) { .page .label { font-size: 10pt } }