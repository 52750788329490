.detailsBlock {
  margin-bottom: 24px;
  padding      : 20px;
  border       : 1px #e2e2e2 solid;
  border-radius: 5px;
  position     : relative;
  box-shadow   : 0px 2px 3px lightgrey;
}

.detailsBlock.admin {
  border-color: red;
  border-width: medium;
}

@media (max-width: 575.98px) {
  .detailsBlock {
    margin-bottom: 10px;
    padding      : 15px;
  }
}

.detailsBlock > .title {
  color: rgb(23, 43, 77);
  font-size: 18px;
  margin-bottom: 3px;
}

.detailsBlock .sideImage {
  max-width: 250px;
  max-height: 300px;
  align-self: center;
  justify-self: center;
}

@media (max-width: 900px) { .detailsBlock .sideImage { display: none; } }