@import '../../style.css';


#ViewScoring .horizontal-line {
  background-color: var(--light-acugrey);
}

#ViewScoring .indexSeverity {
  display: flex;
  justify-content: center;
}

#ViewScoring .indexSeverity * {
  width: max-content;
  min-width: 120px;
  padding: 2px 5px;
  border-radius: 5px;
}

#ViewScoring .th {
  font-size: 15pt;
  font-weight: 800;
  text-transform: uppercase;
}

#ViewScoring .help {
  margin-left: 3px;
  margin-top : 2px;
  line-height: 0;
}

#ViewScoring .indexRow {
  background-color: var(--acuwhite);
  color: var(--font-color);
  font-weight: 600;
  padding: 5px;
  border-top: solid 1px var(--light-acugrey);
}

#ViewScoring .indexRow:last-child {
  border-bottom: solid 1px var(--light-acugrey);
}

#ViewScoring .indexRow:hover {
  background-color: #f5f5f5;
}

#ViewScoring .sectionHeader .actions {
  margin-left: auto;
}



#IndexHelp .indexDescription {
  text-transform: none;
  font-weight: 400;
  max-width: 400px;
}

#IndexHelp svg {
  color: var(--acublue);
}

#IndexHelp .footnote {
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 10px;
  max-width: 460px;
}

#IndexHelp .footnote .icon {
  width: 24px;
}

#IndexHelp .note {
  font-style: italic;
}

#IndexHelp .note:not(:last-child) {
  margin-bottom: 5px;
}

#IndexHelp .indexName {
  font-weight: bold;
  margin-right: 10px;
  min-width: max-content;
  width: 30px;
}

.toolptip-alignment {
  display: flex;
  justify-content: flex-start;
}

.toolptip-alignment.warning {
  background-color: transparent !important;
}

#ViewScoring #chart-index-svg .text {
  fill: white;
  font-weight: 800;
  font-size: 9pt;
}

@media (max-width: 500px) {
  #ViewScoring .th {
    font-size: small;
  }
}

@media (max-width: 375px) {
  #ViewScoring .th {
    font-size: 7.5pt;
  }

  #ViewScoring [class*="odi"] {
    font-size: 7pt;
  }

  #ViewScoring .grid-col > *:not(:first-child) {
    margin-left: 0px;
  }
}

#ViewScoring .odi3Rows, #ViewScoring .odi4Rows {
  margin-top: 12px;
  margin-bottom: 12px;
}
